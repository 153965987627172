import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
	selector: 'korviu-loader',
	standalone: true,
	imports: [CommonModule, NzSpinModule, NzIconModule],
	templateUrl: './korviu-loader.component.html',
	styleUrls: ['./korviu-loader.component.scss']
})
export class KorviuLoaderComponent {}
